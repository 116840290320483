@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
  color: black;
  overflow-x: hidden;
  background-color: black;
  color: #fff;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

.main-container,
.header-container,
.footer-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.nav-menu {
  flex-wrap: wrap;
  justify-content: center;
}

.large-logo {
  max-width: 100%;
  height: auto;
}
.sonner-toast {
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(20px);
  border: none !important;
  color: white;
}

/* style={{
  position: "absolute",
  top: "-10px",
  right: "5px",
  cursor: "pointer",
  color: "#666",
  fontSize: "25px",
}} */