@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

.success-container,
.invalid-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
}

.success-container,
.invalid-container h1 {
  font-size: 2rem;
  color: #28a745;
  margin-bottom: 25px !important;
}

.success-container,
.invalid-container p {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.download-pdf-button {
  display: inline-flex;
  align-items: center;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 25px;
  margin-top: 50px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.download-pdf-button:hover {
  background-color: black;
  color: white;
}
