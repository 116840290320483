@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Основной контейнер — фиксирован в правом нижнем углу */
.slick-button-container {
    position: fixed;
    bottom: 30px;
    left: 30px;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 50px;
    transition: background-color 0.5s ease;
    z-index: 999;
  }
  .close-button {
    position: absolute;
    top: -25px;
    left: -2px;
    color: #fff;
    border: none;
    border-radius: 15px;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
  }

/* Фон в зависимости от активной секции */
.slick-button-container.advice {
  background-color: rgba(52, 152, 219, 0.5);
  backdrop-filter: blur(15px);
}

.slick-button-container.close-friends {
  background-color: rgba(231, 76, 60, 0.5);
  backdrop-filter: blur(15px);
}
.slick-button-container.all-prompts {
  background-color: rgba(189, 183, 107, 0.5); /* Khaki с полупрозрачностью */
  backdrop-filter: blur(15px);
}
/* Основной текст (не кликабельный) */
.main-text {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  font-family: "Poppins-Medium";
}

/* Область с кнопкой Crypto и ссылкой на BMC */
.icons {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

/* Стили для иконки Buy Me A Coffee */
.icon {
  width: 24px;
  height: 24px;
  margin-left: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  border-radius: 15px;
}

.icon:hover {
  transform: scale(1.1);
}

/* Стили для кнопки Crypto */
.slick-button2 {
  border: none;
  background: #fff;
  color: #000;
  font-size: 10px;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 25px;
  outline: none;
  transition: all 0.3s ease;
  font-family: "Poppins-Medium";
  margin-bottom: 3px;
}

.slick-button2:hover {
  background: #000;
  color: #fff;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .slick-button-container {
    padding: 6px 14px;
    border-radius: 25px;
  }
  .main-text {
    font-size: 10px;
  }
  .slick-button2 {
    font-size: 10px;
    padding: 3px 5px;
    margin-bottom: 3.5px;
  }
  .icon {
    width: 22px;
    height: 22px;
    margin-left: 4px;
  }
  .close-button {
    display: none;
    position: absolute;
    top: -12px;
    left: -0px;
    font-size: 20px;
  }
}
  @media (max-width: 768px) {
    .slick-button-container {
      bottom: 20px;
      left: 35%;
      transform: translateX(-35%);
      right: auto;
      margin-left: 25px;
    }
  }
  @media (min-width: 768px) {
    .close-button {
        display: none !important; 
    }
  }