@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

.font-poppins-light {
  font-family: "Poppins-Light", sans-serif;
}

.font-poppins-medium {
  font-family: "Poppins-Medium", sans-serif;
}

.font-poppins-bold {
  font-family: "Poppins-Bold", sans-serif;
}

.old-price {
  color: #999;
  margin-left: 5px;
  position: relative;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 1rem;
}
.old-price::after {
  content: "";
  position: absolute;
  top: 45%;
  left: 2.5px;
  width: 100%;
  height: 2px; /* Толщина линии */
  background-color: #999;
  transform: rotate(-30deg); /* Угол наклона линии */
  transform-origin: center;
  pointer-events: none; /* Чтобы линия не мешала взаимодействию с текстом */
}
.explanation-text {
  max-width: 600px;
  margin: 100px auto 0px;
  line-height: 1.6;
  color: #555 !important;
  font-size: 10px !important;
}
.new-price {
  color: #ffffff;
  font-size: 2rem;
  font-weight: bold;
}
.tooltip-custom {
  font-size: 0.875rem; /* Уменьшение размера текста */
  font-weight: 400; /* Нормальная толщина текста */
  color: #999;
  background-color: #333333; /* Тёмный фон для контраста */
}

.tooltip-custom p {
  margin: 4px 0;
  font-size: inherit; /* Наследование размера текста от родителя */
  font-weight: 300; /* Убедитесь, что используется облегчённый шрифт */
}

.tooltip-custom .font-poppins-bold {
  font-weight: 700; /* Только жирные части текста */
}

.group .tooltip-custom {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}
.group:hover .tooltip-custom {
  opacity: 1;
  pointer-events: auto;
}

.price-section {
  display: flex;
  align-items: baseline;
  margin-top: 8px;
}

button {
  margin-top: auto;
}

.group:hover .tooltip-custom {
  opacity: 1;
  pointer-events: auto;
}

.group .tooltip-custom {
  pointer-events: none;
}
.unique-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.unique-modal-content {
  position: relative;
  padding: 40px;
  border-radius: 15px;
  max-width: 600px;
  width: 100%;
  color: white;
}

.courses-modal-title {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 25px;
  margin-bottom: 0px;
  text-align: center;
  color: #999;
}

.courses-modal-description {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 20px;
  line-height: 1.5;
  white-space: pre-line;
  color: white;
  text-align: left;
}

.unique-close-modal-button:hover {
  color: #ccc;
}
@media (max-width: 768px) {
  .courses-modal-description {
    font-size: 15px;
  }
}
.bmc-button-wrapper {
  display: inline-block;
}
.dollar-sign {
  font-size: 0.8em;        /* здесь подберите подходящий размер */
  vertical-align: text-top; /* или top/super, чтобы знак слегка приподнялся */
  margin-right: 2px;       /* можно добавить отступ, если нужно */
}
.bmc-button-img {
  width: 45px;   /* или любой другой размер */
  height: 45px;  /* то же, чтобы было 1:1 */
  object-fit: cover; /* Если надо обрезать/подогнать содержимое */
  border-radius: 10px; /* При желании можно скруглить углы */
  transition: transform 0.2s ease;
}

.bmc-button-img:hover {
  transform: scale(1.05);
}

