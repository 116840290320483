/* src/components/Testimonials.css */

@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.testimonials-section {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px 0;
  text-align: center;
  color: #fff;
}

.testimonials-section h2 {
  font-size: 30px;
  margin-bottom: 80px;
  font-family: "Poppins-Bold";
  color: #fff;
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  margin-bottom: 100px;
  text-align: left; /* Чтобы текст отзывов не центрировался */
}

.testimonial-card {
  display: flex;
  background: transparent;
  border: none;
  align-items: flex-start;
  max-width: 500px;
  width: 100%;
  gap: 20px;
}

.left-part {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

/* Обертка для аватара с градиентной рамкой (как в историях Instagram) */
.avatar-wrapper {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(
    45deg,
    #f09433,
    #e6683c,
    #dc2743,
    #cc2366,
    #bc1888
  );
  position: relative;
  margin-bottom: 10px;
}

.testimonial-avatar {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  object-fit: cover;
}

.verified-icon {
  color: #3897f0;
  margin-left: 5px;
  font-size: 12px;
}

.testimonial-username {
  font-family: "Poppins-Bold";
  font-size: 14px;
  margin-bottom: 5px;
  color: #fff;
  display: flex;
  align-items: center;
}

.testimonial-followers {
  font-family: "Poppins-Medium";
  font-size: 12px;
  margin-bottom: 15px;
  color: #ccc;
}

.testimonial-text {
  font-family: "Poppins-Medium";
  font-size: 14px;
  line-height: 1.6;
  color: #ddd;
  margin: 0;
}

.right-part {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

/* New styles for growth-data */
.growth-data {
  display: none; /* Скрыто по умолчанию */
}

.followers-growth {
  font-family: "Poppins-Bold";
  font-size: 16px;
  color: #fff;
}

.views-growth {
  font-family: "Poppins-Medium";
  font-size: 14px;
  color: #ccc;
}

.video-wrapper {
  position: relative;
  width: 156px;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
}

.testimonial-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.viewsOverlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  padding: 3px 8px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 5px;
}

.eyeIcon {
  font-size: 10px;
}

.clickable-link {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

/* Мобильная адаптация */
/* Базовые стили для больших экранов */
.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  text-align: left;
  /* На больших экранах отображаем как было: несколько отзывов в ряд */
}

/* На мобильных устройствах меняем логику под карусель */
@media (max-width: 768px) {
  .testimonials-container {
    position: relative;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    scroll-padding-left: 20px;
    gap: 0 !important; /* Убедимся, что gap убран */
  }
  .testimonials-section h2 {
    font-size: 18px !important;
  }
  .right-part {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-left: 0px;
  }
  .testimonial-text {
    font-size: 18px;
  }
  .video-wrapper {
    width: 156px !important;
    height: 300px !important;
    align-items: center !important;
    justify-content: center;
  }

  /* Отображаем блок с данными о приросте на мобильных */
  .growth-data {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }

  .growth-data .followers-growth {
    font-size: 22px;
  }

  .growth-data .views-growth {
    font-size: 20px;
  }

  .testimonials-container::-webkit-scrollbar {
    display: none !important;
  }

  .testimonials-container::before {
    content: "";
    display: block;
    flex: 0 0 20px; /* Отступ слева перед первым отзывом */
  }

  .testimonial-card {
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 95%;
    scroll-snap-align: start;
    margin-right: 20px; /* Отступ между карточками */
  }

  .testimonial-card:last-child {
    margin-right: 50px; /* Убираем отступ у последнего, чтобы не было смещения */
  }

  .video-wrapper {
    width: 116px;
    height: 200px;
    align-items: center !important;
    justify-content: center;
  }
}
