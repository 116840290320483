/* src/components/Footer.css */

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
.footer {
  color: white;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
  background-color: black;
}
.footer-container {
  width: 100%;
  max-width: 1200px; /* Не превышает максимальную ширину */
  margin: 0 auto; /* Центрирует контент */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social-icons2 {
  display: flex; /* Устанавливаем flex-контейнер */
  flex-direction: row; /* Выравниваем элементы по горизонтали */
  gap: 7px; /* Добавляем промежуток между иконками */
  justify-content: center; /* Центрируем иконки по горизонтали */
  align-items: center; /* Центрируем иконки по вертикали */
  margin-right: -20px !important;
}

.social-icons2 a {
  color: white;
  margin-right: 15px;
  font-size: 27.5px;
  transition: color 0.3s;
  border-radius: 10px !important;
}

.social-icons2 a:hover {
  color: #ffffffcc;
}

.footer-logo-contact {
  display: flex;
  align-items: center;
}
.footer-main {
  width: 90%;
  max-width: 1200px;
  margin: 40px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.crypto-section {
  flex: 1;
}

.media-kit-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.media-kit-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 15px;
}

.media-kit-image {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.media-kit-image:hover {
  transform: scale(1.025);
}

/* Центрирование блока на больших экранах */
.footer-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
}

/* Модальное окно */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.modal-open {
  opacity: 1;
  visibility: visible;
}

.modal-content {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.modal-image {
  width: 100%;
  max-width: 450px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.download-pdf-button {
  display: inline-flex;
  align-items: center;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.download-pdf-button:hover {
  background-color: #1c1c1c;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 25px;
  background: none;
  border: none;
  color: black;
  font-size: 30px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-modal-button:hover {
  color: darkgrey;
}

@media (max-width: 768px) {
  .footer-main {
    flex-direction: column;
    align-items: center;
  }

  .media-kit-section {
    max-width: 100%;
    width: 90%;
    margin-bottom: 30px;
  }

  .crypto-section {
    width: 100%;
    margin-top: 30px;
  }

  .crypto-addresses {
    width: 100%;
    max-width: none;
  }

  .media-kit-image {
    max-width: 200px;
  }

  .media-kit-title {
    font-size: 18px;
    margin-bottom: 50px;
  }

  .download-pdf-button {
    display: none;
  }
}
.contact-email {
  color: white;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  margin-right: 10px;
  transition: color 0.3s;
}
.downIcon {
  margin-right: 5px;
}
.contact-email:hover {
  color: #ffffffcc;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-logo-image {
  height: 30px;
  margin-right: 5px;
}

.name {
  font-family: "Poppins";
}

.crypto-section {
  text-align: center;
  margin: 0px auto;
}

.crypto-title {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.network-badge {
  background-color: #007bff;
  color: white;
  font-size: 10px;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 5px;
  margin-left: 5px;
  display: inline-block;
}

.descfoot {
  font-weight: bold;
  font-size: 12px;
}

.logoRad {
  height: 23px;
  margin-right: 0px;
}

.footer-text {
  width: 90%;
  max-width: 1200px;
  margin: 20px auto 0;
  text-align: center;
  font-size: 12px;
  line-height: 1.6;
}

.footer-copyright2 {
  margin-top: 20px;
  color: white;
  text-align: center;
  opacity: 0.8;
}
.policy-button {
  margin-top: 100px !important;
  font-family: "Poppins-Medium";
  font-size: 12px;
  color: #999;
  transition: all 0.3s ease;
}
.policy-button:hover {
color: white;
}

.consent-modal-text2 {
  font-family: "Poppins-Light", sans-serif;
  font-size: 14px;
  color: white;
}
.footer-copyright2 p {
  font-size: 12px !important;
  margin-top: 5px !important;
}

.crypto-addresses {
  margin: 50px auto;
  width: 90%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.crypto-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 15px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.crypto-icon {
  width: 25px;
  height: 25px;
  border-radius: 30px;
}

.crypto-name {
  flex: 1;
  margin-left: 10px;
  font-weight: bold;
  font-size: 14px;
}

.crypto-address {
  flex: 2;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins-Light";
  color: #999 !important;
  white-space: nowrap;
}
.bmc-link-foot {
  display: flex;
  align-items: center;
}

.bmc-icon-foot {
  width: auto; 
  height: 25px;
  object-fit: contain;
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.bmc-icon-foot:hover {
  transform: scale(1.05);
}
.copy-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  transition: color 0.3s;
}

.copy-button:hover {
  color: black;
}
@media (min-width: 768px) {
  .footer-logo-contact2 {
    display: none;
  }
  .footer-main {
    margin: 150px auto 0;
  }
}
@media (max-width: 768px) {
  .footer-container {
    flex-direction: row;
  }
  .social-icons2 a {
    font-size: 22px;
  }
  .bmc-icon-foot {
  width: 20px;
  height: auto;
  }
  .social-icons2 {
    margin: 0 20px;
  }
  .footer-logo-contact {
    flex-direction: row;
    display: none;
  }
  .crypto-section {
    margin-top: 50px;
  }
  .network-badge {
    font-size: 6px;
    margin-left: 4px;
  }
  .contact-email {
    font-size: 12px;
    margin-right: 8px;
  }
  .contact-email {
    margin: 0 25px;
  }
  .footer-logo-image {
    height: 25px;
    margin-right: 4px;
  }
  .crypto-address {
    margin-left: 10px;
  }
  .name {
    font-size: 14px;
  }
}
