/* Подключение шрифтов */
@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Основные стили */
.main {
  padding: 125px 0 50px 0;
}

.mainContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  position: relative;
}
.dollar-sign {
  font-size: 0.8em;         /* уменьшает размер символа $ */
  vertical-align: text-top; /* поднимает символ чуть выше, если нужно */
  margin-right: 2px;        /* небольшой отступ справа, по желанию */
}
.descriptionText2 {
  font-size: 24px !important;
  line-height: 1.5;
  font-family: "Poppins-Medium";
  margin-bottom: 50px;
  text-align: center;
  color: grey;
}

.leftSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.leftSection h1 {
  font-size: 50px;
  margin-bottom: 20px;
  font-family: "Poppins-Bold" !important;
}

.leftSection p {
  font-size: 20px;
  line-height: 1.5;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid black;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tokenData {
  width: 90%;
  max-width: 1200px;
  margin: 50px auto 0px;
  padding: 20px;
  border-radius: 10px;
  margin-top: 100px;
  /* Убрали фон */
}

.tokenData h2 {
  text-align: center;
  font-size: 40px;
  margin-bottom: 40px; /* Увеличен отступ для нового макета */
  font-family: "Poppins-Bold";
}

.tokenCardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}

.aboutContent {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Умеренный промежуток */
}

.textTop,
.textBottom {
  font-family: "Poppins-Medium";
  line-height: 2;
  text-align: center; /* Добавлено для центрирования */
}

.textTop p,
.textBottom p {
  margin-bottom: 0px; /* Уменьшенный отступ */
  font-size: 20px;
}

.centerSection {
  display: grid;
  grid-template-areas: "left video right";
  grid-template-columns: 1fr auto 1fr;
  align-items: start;
  gap: 10px; /* Минимальный промежуток */
}

.textLeft {
  grid-area: left;
  font-family: "Poppins-Medium";
  line-height: 2;
  font-size: 20px;
}

.textRight {
  text-align: right;
  font-family: "Poppins-Medium";
  line-height: 2;
  font-size: 20px;
}

.centerVideoWrapper {
  grid-area: video;
  position: relative;
  width: 175px; /* Уменьшенный размер видео */
  margin: 0 20px; /* Минимальные отступы */
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Чтобы свечение не выходило за пределы */
}
.glow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle,
    rgba(139, 0, 0, 0.6) 0%,
    rgba(139, 0, 0, 0) 70%
  );
  transform: translate(-50%, -50%);
  animation: glowAnimation 6s infinite alternate;
  z-index: 1; /* За видео */
  pointer-events: none; /* Чтобы не мешало взаимодействию с видео */
  border-radius: 10px; /* Сохраняем скругление */
}

@keyframes glowAnimation {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.4;
  }
  50% {
    transform: translate(-50%, -50%) scale(1.05);
    opacity: 0.5;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0.4;
  }
}
.centerViewsOverlay {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  padding: 4px 8px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 2px;
}

.contractLink {
  color: #1e90ff;
  text-decoration: none !important;
  transition: ease 0.3s all;
}

.contractLink:hover {
  color: blue;
}

/* Медиа-запросы для устройств с шириной от 768px */
@media (min-width: 768px) {
  .reelsContainer {
    gap: 20px;
  }
}

/* Медиа-запросы для устройств с шириной до 768px */
/* Медиа-запросы для устройств с шириной до 768px */
@media (max-width: 768px) {
  .mainContainer {
    flex-direction: column;
    text-align: center;
    padding: 0 20px;
  }

  .descriptionText2 {
    padding: 0;
    margin-bottom: 30px;
    font-size: 18px !important;
  }

  .leftSection h1 {
    font-size: 30px;
  }

  .leftSection p {
    font-size: 16px;
  }

  .main {
    padding-top: 250px;
  }

  .tokenData {
    width: 95%;
    margin-top: 0px;
    padding: 20px;
  }

  .tokenData h2 {
    font-size: 30px;
  }

  .tokenCardsContainer {
    flex-direction: column;
    align-items: center;
  }

  .reelsContainer {
    gap: 10px;
  }

  .reel {
    width: 116px !important;
    height: 200px !important;
  }

  .viewsOverlay {
    bottom: 10px !important;
    right: 10px !important;
    font-size: 10px !important;
    padding: 2px 4px;
  }

  .eyeIcon {
    font-size: 8px !important;
  }

  .aboutContent p {
    font-size: 14px;
    margin-bottom: 15px;
    position: relative; /* Для наложения */
    z-index: 2; /* Поверх видео */
    text-align: center; /* Центрирование текста */
  }

  .centerSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
  }

  /* Центрирование текста */
  .textLeft,
  .textRight {
    max-width: 100%;
    padding: 0 10px;
  }

  /* Центрирование видео */
  .centerVideoWrapper {
    width: 100%;
    max-width: 200px; /* Максимальная ширина для видео */
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  /* Стили для дополнительного видео */
  .additionalMobileVideoWrapper {
    position: relative;
    width: 100%;
    max-width: 300px; /* Максимальная ширина для дополнительного видео */
    margin: 40px auto 20px auto; /* Центрирование и отступы */
    overflow: hidden;
    border-radius: 10px;
  }

  .additionalMobileVideo {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
  }

  .additionalViewsOverlay {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: #fff;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(5px);
    padding: 3px 6px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 3px;
  }

  /* Стили для перекрывающего текста */
  .overlayTextTop,
  .overlayTextBottom {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    width: 90%;
    text-align: center;
  }

  .overlayTextTop {
    top: 10px;
  }

  .overlayTextBottom {
    bottom: 10px;
  }
}

/* Стили для контейнера роликов */
.reelsContainer {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 30px;
}

.reelWrapper {
  position: relative;
}

.reel {
  width: 250px;
  height: 400px;
  border-radius: 10px;
  scroll-snap-align: center;
  object-fit: cover;
  cursor: pointer;
  background-color: #000;
  position: relative;
  overflow: hidden;
}

.reelsContainer::-webkit-scrollbar {
  display: none !important;
}

.viewsOverlay {
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: #fff;
  font-size: 14px;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(15px);
  padding: 5px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 5px;
}

.eyeIcon {
  font-size: 10px;
}

.ctaButton {
  background: #000;
  color: #fff;
  padding: 15px 30px;
  border: none;
  font-family: "Poppins-Bold";
  font-size: 12px;
  border-radius: 25px;
  cursor: pointer;
  transition: ease 0.3s all;
  margin-top: 40px;
  margin-bottom: 50px;
}

.ctaButton:hover {
  background: #333;
}
/* Исходные стили (оставьте как есть) */

.merchSection {
  display: flex;
  flex-direction: row;
  gap: 100px; /* старое значение */
  align-items: flex-start;
  justify-content: center;
}

.merchLeft {
  display: flex;
  justify-content: center;
  align-items: center;
}

.merchRight p {
  margin-bottom: 40px !important;
}

.tshirtContainer {
  width: 500px;
  height: 500px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.merchRight h3 {
  margin-bottom: 30px !important;
}

.tshirtImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease;
}

.merchRight {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
  margin-top: 40px;
}

.buttonRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.soldText {
  margin-bottom: 10px;
  color: #999;
  font-size: 10px;
}
.priceDisplayTransform {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: white;
  margin: 0;
}
.sizeBadgeContainer {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.sizeBadge {
  border: 1px solid white;
  border-radius: 4px;
  background-color: black;
  color: white;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Poppins-Medium", sans-serif;
}

.sizeBadge:hover:not(.disabledSize) {
  background-color: white;
  color: black;
}

.selectedSize {
  background-color: white;
  color: black;
}

.disabledSize {
  background-color: #4f4f4f !important;
  text-decoration: line-through;
  cursor: not-allowed;
}

.disabledButton {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  transition: opacity 0.3s;
}

.enabledButton {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.3s;
}

.descMerch {
  font-family: "Poppins-Light" !important;
  font-size: 15px;
}

.orderButton {
  background: #c00;
  color: #fff;
  padding: 12px 20px;
  border: none;
  font-family: "Poppins-Bold";
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
  transition: ease 0.3s all;
}

.orderButton:hover {
  background: #900;
}

/* -------------------- */
/*       MEDIA QUERY    */
/* -------------------- */
@media (max-width: 768px) {
  /* Делаем саму секцию колонкой, но кнопки всё ещё остаются в ряд */
  .merchSection {
    flex-direction: column; /* Правая секция под левой */
    gap: 20px;
    align-items: center;
  }
  .merchTitle {
    font-size: 22px !important;
  }
  .tshirtContainer {
    width: 280px; /* Уменьшаем футболку для мобильных */
    height: 280px;
  }

  .merchRight {
    margin-top: 20px;
    max-width: 90%; /* Шире текст на мобильных */
    align-items: center; /* Текст и элементы по центру */
    text-align: center; /* Можно выровнять текст по центру */
  }

  .soldText {
    font-size: 10px; /* Чуть крупнее, чтобы не было совсем мелко */
  }

  .sizeBadge {
    font-size: 12px;
    padding: 0.4em 1em;
  }

  .buttonRow {
    /* Оставляем их в строку, просто уменьшим отступы, если нужно */
    margin-top: 30px;
    gap: 8px; /* Вместо 10px, если хотите чуть меньше расстояние */
  }
}
