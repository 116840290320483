/* src/components/BuyButton.css */

.buy-button {
  position: relative; /* Для позиционирования снежинок */
  background: #fff;
  background-size: 1200% 1200%; /* Увеличиваем размер для более плавной анимации */
  color: #000;
  padding: 10px 20px; /* Увеличенный padding для более крупной кнопки */
  font-size: 10px; /* Увеличенный размер шрифта */
  font-weight: 700; /* Жирный текст */
  cursor: pointer;
  transition: transform 0.3s;
  font-weight: bold;
  border: none; /* Убрана граница */
  border-radius: 25px; /* Округлые края кнопки */
  animation: GradientAnimation 20s ease infinite; /* Увеличиваем длительность анимации */
  overflow: hidden; /* Скрыть снежинки выходящие за границы кнопки */
}

.buy-button:hover {
  transform: scale(1.05); /* Лёгкое увеличение при наведении */
}

/* Анимация градиента */
@keyframes GradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Стили для снежинок */
.snowflake {
  position: absolute;
  top: -10px; /* Начальная позиция сверху кнопки */
  width: 5px;
  height: 5px;
  background: black;
  border-radius: 50%;
  opacity: 0.8;
  pointer-events: none; /* Чтобы не блокировать клики по кнопке */
  animation: snowfall 5s linear infinite;
}

/* Анимация падения снега с хаотичным движением */
@keyframes snowfall {
  0% {
    transform: translate3d(var(--x-initial, 0px), 0, 0) rotate(0deg);
    opacity: 0.8;
  }
  100% {
    transform: translate3d(var(--x-final, 0px), 60px, 0) rotate(360deg);
    opacity: 0;
  }
}

/* Генерация уникальных свойств для каждой снежинки */
.buy-button .snowflake:nth-child(1) {
  left: 10%;
  animation-duration: 6s;
  animation-delay: 0s;
  --x-initial: -10px;
  --x-final: 10px;
}

.buy-button .snowflake:nth-child(2) {
  left: 20%;
  animation-duration: 7s;
  animation-delay: 1s;
  --x-initial: 15px;
  --x-final: -15px;
}

.buy-button .snowflake:nth-child(3) {
  left: 30%;
  animation-duration: 5s;
  animation-delay: 2s;
  --x-initial: -20px;
  --x-final: 20px;
}

.buy-button .snowflake:nth-child(4) {
  left: 40%;
  animation-duration: 8s;
  animation-delay: 3s;
  --x-initial: 25px;
  --x-final: -25px;
}

.buy-button .snowflake:nth-child(5) {
  left: 50%;
  animation-duration: 6s;
  animation-delay: 4s;
  --x-initial: -30px;
  --x-final: 30px;
}

.buy-button .snowflake:nth-child(6) {
  left: 60%;
  animation-duration: 7s;
  animation-delay: 5s;
  --x-initial: 35px;
  --x-final: -35px;
}

.buy-button .snowflake:nth-child(7) {
  left: 70%;
  animation-duration: 5s;
  animation-delay: 6s;
  --x-initial: -40px;
  --x-final: 40px;
}

.buy-button .snowflake:nth-child(8) {
  left: 80%;
  animation-duration: 8s;
  animation-delay: 7s;
  --x-initial: 45px;
  --x-final: -45px;
}

.buy-button .snowflake:nth-child(9) {
  left: 15%;
  animation-duration: 6s;
  animation-delay: 8s;
  --x-initial: -50px;
  --x-final: 50px;
}

.buy-button .snowflake:nth-child(10) {
  left: 25%;
  animation-duration: 7s;
  animation-delay: 9s;
  --x-initial: 55px;
  --x-final: -55px;
}

/* Повторяем стиль для остальных снежинок, если их больше */
.buy-button .snowflake:nth-child(n + 11) {
  left: calc(10% + (n - 10) * 5%);
  animation-duration: 5s + (n % 3) * 2s;
  animation-delay: calc(1s * (n % 5));
  --x-initial: calc(-20px + 5px * (n % 4));
  --x-final: calc(20px + 5px * (n % 4));
}

/* Медиа-запросы для адаптивности */
@media (max-width: 768px) {
  .buy-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 10px;
  }

  .snowflake {
    width: 3px;
    height: 3px;
  }

  /* Обновлённые свойства снежинок для мобильных устройств */
  .buy-button .snowflake:nth-child(1) {
    --x-initial: -5px;
    --x-final: 5px;
  }

  .buy-button .snowflake:nth-child(2) {
    --x-initial: 7px;
    --x-final: -7px;
  }

  /* Продолжите для остальных снежинок при необходимости */
}
@keyframes discount-flash {
  0% {
    transform: scale(1);
    color: #999;
  }
  50% {
    transform: scale(1.2);
    color: #f00;
  }
  100% {
    transform: scale(1);
    color: #999;
  }
}

.animate-discount {
  animation: discount-flash 0.5s ease-in-out;
}
