@font-face {
    font-family: "Poppins-Medium";
    src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Poppins-Light";
    src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
  }
.details-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
  }
  
  .details-modal-content {
    position: relative;
    padding: 30px 20px;
    border-radius: 15px;
    max-width: 450px;
    width: 90%;
    color: #fff;
    text-align: left; /* Выровнять текст по левому краю */
  }
  
  .modal-title {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 1.5rem;
    color: white; /* Серый цвет */
    margin-bottom: 20px;
  }
  
  .section-title {
    font-family: "Poppins-Bold", sans-serif;
    font-size: 1.2rem;
    color: #999; /* Серый цвет */
    margin-bottom: 10px;
    margin-top: 25px;
  }
  
  .section-item {
     font-family: "Poppins-Light";
    font-size: 0.9rem;
    color: #ffffff; /* Белый цвет */
    margin-bottom: 8px;
  }
  