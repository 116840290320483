@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

.video-carousel {
  text-align: center;
  color: #fff;
  padding: 20px;
}

.carousel-title {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 2rem;
  margin-bottom: 50px;
  display: inline-flex;
  align-items: center;
}

.title-info-icon {
  font-size: 1rem;
  margin-left: 8px;
  cursor: pointer;
  color: #aaa;
}

.video-container {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  flex-wrap: nowrap;
  overflow-x: auto;
  /* padding-bottom: 20px; */
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
}
.video-container::-webkit-scrollbar {
  display: none !important;
}

.badge-container {
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel-video {
  width: 150px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  pointer-events: none !important;
}

.price-section-transform {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 10px 0; */
}

.price-display-transform {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  font-size: 3.5rem;
  color: white;
  margin: 0;
}
.price-display-transform2 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: bold;
  font-size: 2rem;
  color: white;
  margin: 0;
}
.duration-display-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.duration-display {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 1.2rem;
  color: #999;
  margin-top: 0;
}

.duration-info-icon {
  font-size: 1rem;
  margin-top: 0;
}

.order-button {
  margin-top: 30px;
  padding: 10px 20px;
  background-color: #ffffff;
  color: #1a1a1a;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.order-button:hover {
  background-color: #e0e0e0;
}
.dollar-sign {
  font-size: 0.8em; /* уменьшает размер символа $ */
  vertical-align: text-top; /* поднимает символ чуть выше, если нужно */
  margin-right: 2px; /* небольшой отступ справа, по желанию */
}
.active-order-button {
  background-color: #4caf50;
  color: white;
}

.note-text {
  max-width: 400px;
  margin: 50px auto 0px;
  line-height: 1.6;
  color: #555 !important;
  font-size: 10px !important;
}

.unique-consent-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.unique-consent-modal-content {
  position: relative;
  padding: 30px;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
  color: white;
  max-height: 80vh;
  overflow-y: auto;
}

.consent-modal-title {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;
}

.consent-modal-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.titleAgree {
  color: #888;
  font-family: "Poppins-Bold", sans-serif;
  font-size: 20px !important;
}

.consent-modal-text {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  color: white;
}

.consent-modal-list {
  list-style-type: disc;
  padding-left: 20px;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  text-align: left !important;
}

.consent-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
}

.consent-checkbox-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.consent-checkbox-text {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 10px;
  text-align: left !important;
  color: #888;
}

.consent-confirm-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 25px;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  transition: all 0.3s ease;
}

.modal-arrow-down {
  position: fixed;
  bottom: 10%;
  left: 25%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  z-index: 9999;
}

.consent-confirm-button.disabled {
  background-color: #333;
  cursor: not-allowed;
}

.consent-confirm-button.enabled:hover {
  background-color: #999;
}

.check-icon {
  margin-right: 5px;
}

.unique-duration-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.unique-duration-modal-content {
  position: relative;
  padding: 30px;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
  color: white;
}

.duration-modal-title {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
  color: #666;
}

.duration-modal-description {
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: white;
}

@media (min-width: 768px) {
  .video-container {
    justify-content: center;
  }
}

.unique-consent-modal-content::-webkit-scrollbar {
  display: none !important;
}

@media (max-width: 768px) {
  .modal-arrow-down {
    left: 5% !important;
  }
  .carousel-title {
    font-size: 22px !important;
  }
  .unique-title-modal-content {
    margin-top: 50% !important;
  }
}

.order-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.bmc-button-link2 {
  display: inline-block;
  margin-top: 30px;
}

.bmc-button-link3 {
  display: inline-block;
  margin-top: 0px;
}

.bmc-button-img2 {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.bmc-button-img3 {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.bmc-button-img2:hover {
  transform: scale(1.05);
}

.video-wrapper {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.badge-container {
  margin-bottom: 20px;
  width: 150px;
  display: flex;
  justify-content: center;
}

.gradient-badge {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  cursor: pointer;
  background: linear-gradient(270deg, #6a6f40, #9ea77a, #6a6f40);
  background-size: 600% 600%;
  animation: gradientAnimation 6s ease infinite;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.badge-text {
  color: #fff;
  font-family: "Poppins-Bold", sans-serif;
  font-size: 0.8rem;
}

.badge-icon {
  color: #fff;
  font-size: 1rem;
}

.gradient-badge:hover {
  animation-play-state: paused;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.video-overlay-container {
  position: relative;
  width: 150px;
  height: 200px;
}

.carousel-video {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  pointer-events: auto;
}

.prompt-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 10px;
}

.prompt-overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: auto;
}

.prompt-price {
  font-size: 1.75rem;
  color: #fff;
  margin-bottom: 15px;
  font-weight: bold;
}

.prompt-buttons {
  display: flex;
  gap: 10px;
}

.prompt-buttons button {
  background-color: #ffffff;
  color: #000;
  border: none;
  border-radius: 5px;
  padding: 8px 14px;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.prompt-buttons button:hover {
  background-color: #ddd;
}

.unique-title-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.unique-title-modal-content {
  position: relative;
  padding: 30px;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
  color: white;
  margin-top: 15%;
}

.title-modal-heading {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
  color: #666;
}

.title-modal-text {
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  margin-bottom: 25px;
}

.recommended-pack-title {
  margin-top: 100px;
  font-family: "Poppins-Bold", sans-serif;
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.recommended-pack-badge {
  width: auto;
  height: 30px;
  display: inline-flex;
  align-items: center;
  padding: 0 10px;
  background: linear-gradient(90deg, #6a6f40, #9ea77a);
  border-radius: 25px;
  margin-bottom: 15px;
}

.recommended-pack-badge-text {
  color: #fff;
  font-family: "Poppins-Bold", sans-serif;
  font-size: 0.85rem;
}

.recommended-pack-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
