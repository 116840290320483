@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Общая стилизация секции */
.token-section {
  text-align: center;
  color: #fff;
  padding: 20px;
}

/* Заголовок */
.token-title {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 2rem;
  margin-bottom: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.title-info-icon {
  font-size: 1rem;
  margin-left: 8px;
  cursor: pointer;
  color: #aaa;
}

/* NEW: общий wrapper для левой и правой колонок */
.token-content-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Левая колонка */
.left-content {
  flex: 1;
}

/* Правая колонка */
.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Контейнер для GIF и адреса */
.gif-and-address {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
}

.token-gif {
  width: 75px;
  height: auto;
  object-fit: cover;
  margin: 0;
  margin-right: 200px;
}

/* Само поле адреса */
.address-field {
  display: inline-flex;
  align-items: center;
  background: #222;
  border: 1px solid #555;
  border-radius: 8px;
  padding: 10px 14px;
  gap: 10px;
  margin-top: 0px;
}

.address-text {
  font-family: "Poppins", sans-serif;
  font-size: 0.85rem;
  color: #ccc;
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}

.address-icons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.external-link-icon,
.copy-icon {
  color: #ccc;
  font-size: 1rem;
  cursor: pointer;
  transition: color 0.2s ease;
}

.external-link-icon:hover,
.copy-icon:hover {
  color: #fff;
}

.note-text2 {
  max-width: 400px;
  margin: 50px auto 0px;
  line-height: 1.6;
  color: #555 !important;
  font-size: 10px !important;
}

/* ---- Блок справа (Market Cap, Holders) ---- */
.market-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px; /* расстояние между блоками Market Cap и Holders */
}

/* Крупное жирное число */
.big-value {
  font-size: 5rem;
  font-weight: 700;
  color: #fff;
  margin: 0;
  line-height: 1.2;
}

/* Мелкая подпись */
.small-label {
  font-size: 1rem;
  font-weight: 400;
  color: #555 !important;
  margin: 0;
  line-height: 1.2;
  font-family: "Poppins-Light", sans-serif;
}

/* Модальное окно */
.unique-title-modal-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.unique-title-modal-content2 {
  position: relative;
  padding: 30px;
  border-radius: 15px;
  max-width: 500px;
  width: 90%;
  color: white;
  max-height: 80vh;
  overflow-y: auto;
}

.title-modal-heading {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 22px;
  margin-bottom: 15px;
  text-align: center;
  color: #666;
}

.title-modal-text {
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: white;
  margin-bottom: 25px;
}

.unique-title-modal-content2::-webkit-scrollbar {
  display: none !important;
}

/* --- Мобильные стили --- */
@media (max-width: 768px) {
  .token-title {
    font-size: 22px !important;
  }
  .title-modal-heading {
    font-size: 20px;
  }
  .title-modal-text {
    font-size: 14px;
  }
  .market-info {
    margin-top: 50px;
  }
  .token-content-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .token-gif {
    margin-right: 100px;
  }
  .address-text {
    display: inline-block;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .gif-and-address {
    margin-top: 40px;
  }
}
