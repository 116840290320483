.separator {
    border: none;
    border-top: 1px solid #222;
    margin: 50px auto; /* Верх и низ по 50px, слева и справа автоматически */
    width: 82.5%; /* Задайте желаемую ширину */
}
.separator2 {
    border: none;
    border-top: 1px solid #222;
    margin: 25px auto; /* Верх и низ по 50px, слева и справа автоматически */
    width: 82.5%; /* Задайте желаемую ширину */
  }