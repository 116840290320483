.unique-InfinityLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: black;
  position: relative;
  overflow: hidden;
}

.unique-InfinityLoader-svg {
  width: 85.67px;
  height: 42.33px;
}

.ip__track {
  stroke: #aaaaaa; /* Серый цвет для основного знака */
}

.ip__worm1,
.ip__worm2 {
  stroke: #ffffff; /* Белый цвет для двигающейся полоски */
  animation: worm1 2s linear infinite;
}

.ip__worm2 {
  animation-name: worm2;
}

/* Анимации */
@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }
  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -358;
  }
}
