/* src/components/Header.css */
@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
.wallet-adapter-button {
  background-color: white !important; /* Задайте нужный цвет */
  border-radius: 25px !important; /* Закругленные углы */
  color: black !important; /* Цвет текста */
  padding: 10px 20px !important; /* Отступы */
  font-size: 16px !important; /* Размер шрифта */
  transition: all 0.3s ease;
}

.wallet-adapter-button:hover {
  transform: scale(1.05);
}
.header {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  padding: 20px 0; /* Увеличено пространство сверху и снизу */
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.header-container {
  width: 100%;
  max-width: 1200px; /* Не превышает максимальную ширину */
  margin: 0 auto; /* Центрирует контент */
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo {
  height: 36px; /* Увеличен размер логотипа */
  margin-right: 5px;
}

.logo-text {
  color: white;
  font-size: 32px; /* Увеличен размер шрифта */
  font-weight: 800; /* Сделано жирнее */
  /* font-family: "DMSerifText-Italic"; */
  text-decoration: none;
}

.nav-menu {
  display: flex;
  align-items: center; /* Центрирует элементы по вертикали */
}

.nav-menu a {
  color: white;
  margin: 0 10px; /* Уменьшены промежутки между ссылками */
  text-decoration: none;
  font-size: 15px; /* Увеличен размер шрифта */
  font-weight: 600; /* Сделано жирнее */
  display: flex; /* Позволяет выровнять иконку и текст */
  align-items: center; /* Центрирует иконку и текст по вертикали */
  transition: color 0.3s ease;
}

.nav-menu a:hover {
  color: #999; /* Плавный переход к черному цвету при наведении */
}
.infinity-symbol {
  font-family: Arial, sans-serif;
  font-size: 1em; /* Подстройте размер, чтобы соответствовать шрифту текста */
  display: inline-block;
  vertical-align: middle;
}

.social-icon2 {
  margin-right: 5px; /* Отступ между иконкой и текстом */
  width: 20px !important; /* Ширина иконки */
  height: 20px !important; /* Высота иконки */
  object-fit: contain; /* Сохраняет соотношение сторон */
}
section {
  scroll-margin-top: 100px; /* Отступ для правильного позиционирования */
}
@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
  }
  .right-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Убедитесь, что ширина равна ширине экрана */
    margin-top: 20px; /* Добавляем отступ сверху */
  }

  .large-logo {
    width: 60%; /* Уменьшаем логотип для мобильных */
    height: auto;
    margin-top: 50px !important;
  }
  .nav-menu {
    margin: 15px 0; /* Добавлен отступ между логотипом и меню */
  }
  .logo-text {
    font-size: 20px;
  }
  .nav-menu a {
    margin: 0 8px; /* Уменьшен отступ между ссылками */
    font-size: 18px !important;
  }
  .logo-section {
    margin-bottom: 10px;
  }
  .buy-button {
    margin-top: 10px !important;
  }
  .social-icon2 {
    margin-right: 5px; /* Отступ между иконкой и текстом */
    width: 22px; /* Ширина иконки */
    height: 22px; /* Высота иконки */
    object-fit: contain; /* Сохраняет соотношение сторон */
  }
}
/* Стили для знака бесконечности */
.infinity-symbol {
  display: inline-block;
  vertical-align: middle;
  width: 32px; /* Пропорционально тексту */
  height: 16px;
}

.infinity-svg {
  width: 100%;
  height: 100%;
}

.infinity-track {
  stroke: #aaaaaa; /* Серый цвет для основного знака */
}

.infinity-worm1,
.infinity-worm2 {
  stroke: #ffffff; /* Белый цвет для двигающихся линий */
  animation: worm1 2s linear infinite;
}

.infinity-worm2 {
  animation-name: worm2;
}

/* Анимации */
@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }
  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -358;
  }
}

/* Медиазапрос для мобильных устройств */
@media (max-width: 768px) {
  .infinity-symbol {
    width: 20px; /* Уменьшение размера для мобильных */
    height: 10px;
  }
}
