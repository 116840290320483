@font-face {
    font-family: "Poppins-Light";
    src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-Medium";
    src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins-Bold";
    src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  }
  
.cancel-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    text-align: center;
  }
  .downIcon2 {
    font-size: 20px;
    margin-right: 5px;
  }
  .cancel-container h1 {
    font-size: 2rem;
    color: white;
    font-family: "Poppins-Bold";
    margin-bottom: 10px;
  }
  
  .cancel-container p {
    font-size: 1.2rem;
    color: #999;
    margin-bottom: 20px;
    font-family: "Poppins-Medium";
  }
  
  .back-button {
    background-color: #dc3545;
    color: #fff;
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    margin-top: 50px;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .back-button:hover {
    background-color: #c82333;
  }
  