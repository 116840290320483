/* GratitudeModal.css */
@font-face {
  font-family: "Poppins-Light";
  src: url("../assets/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

/* Полупрозрачный фон поверх всего */
.gratitude-global-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

/* Контентное окно */
.gratitude-global-content {
  position: relative;
  padding: 30px 20px;
  border-radius: 15px;
  max-width: 450px;
  width: 90%;
  color: #fff;
  text-align: center;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE и старый Edge */
}

.gratitude-global-list::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari, Opera */
}

/* Заголовок */
.gratitude-global-title {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 24px;
  margin-bottom: 10px;
}

/* Подзаголовок */
.gratitude-global-subtitle {
  font-family: "Poppins-Medium";
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

/* Список */
.gratitude-global-list {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
  max-height: 500px; /* прокрутка, если слишком много имен */
  overflow-y: auto;
}

.gratitude-global-item {
  display: flex;
  align-items: center;
  justify-content: center; /* Центрируем по горизонтали */
  margin: 8px 0;
  padding: 8px 12px;
  border-radius: 5px;
}

.gratitude-global-nick {
  font-family: "Poppins-Bold", sans-serif;
  font-size: 20px;
  color: #fff;
  /* Можно добавить небольшой отступ справа, чтобы имя и сумма не были слишком близко */
  margin-right: 10px;
}

.gratitude-global-amount {
  /* font-family: "Poppins-Medium", sans-serif; */
  font-size: 20px;
  font-weight: bold;
  color: #aaa;
  /* Если хотите убрать отступ, удалите margin-left. */
}

/* Кнопка закрыть */
.gratitude-global-close {
  background: #444;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.gratitude-global-close:hover {
  background: #666;
}
@media (max-width: 768px) {
  .gratitude-global-nick {
    font-size: 12px;
  }
  .gratitude-global-amount {
    font-size: 15px;
  }
}
